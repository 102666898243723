/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { format } from "date-fns";

// require("dotenv").config();

const baseURL = process.env.REACT_APP_BASE_URL;

export const GET_NEW_TOKEN_URL = () => {
  if (process.env.REACT_APP_NODE_ENV === 'production') {
    return 'https://users.livemarketdata.com/v3/token/create';
  } else {
    return 'https://users-staging.livemarketdata.com/v3/token/create';
  }
}

const errorHandler = (error) => {
  return { error };
};

export function setCookie(key, value) {
  document.cookie = `${key}=${value}; path=/`;
  window[key] = value;
}

export function readCookie(key) {
  if (typeof window[key] !== "undefined") {
    return window[key];
  }
  let nameEQ = key + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export default {
  getToken: async () =>
    axios
      .get(process.env.REACT_APP_TOKEN_URL)
      .then((response) => {
        setCookie("access_token", response.data.token);
      })
      .catch(errorHandler),

  getPerformanceData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/exchanges/*/markets/*/tradables/${symbol}/statistics`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getPriceData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/ir/equities/symbol/${symbol}/delayed_snapshot`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getShareData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}static_data/v1/exchanges/*/tradables/${symbol}?skip_expired=true&bond=false&share=true`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getCompanyFigures: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/company_reports/symbol/${symbol}/company_figures`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getChartData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/tradables/${symbol}/history_timeseries_short/?from_date=1970-02-10&to_date=${format(
          new Date(),
          "yyyy-MM-dd"
        )}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getHistoryData: async (symbol, startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/tradables/${symbol}/history_timeseries_short?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getHistoryDataWithCurrency: async (currency, startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/currency_cross/base_currency/CBI/cross_currency/${currency}/history_timeseries?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTotalIncomeData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/questor/key_series/?ticker=${symbol}&key_id=910100&periods=Y`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getEarningsData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/questor/key_series/?ticker=${symbol}&key_id=910700&periods=Y`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getEarningsPerShareData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/questor/key_series/?ticker=${symbol}&key_id=920560&periods=Y`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getShareHolders: async (symbol, date = format(new Date(), "yyyy-MM-dd")) => {
    const token = readCookie("access_token");
    return axios
      .get(`${baseURL}market_data/v1/${symbol}/shareholders?date=${date}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .catch(errorHandler);
  },

  getTrades: async (symbol, limit = 10) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/exchanges/*/markets/*/tradables/${symbol}/delayed_latest_trades?limit=${limit}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getNews: async (symbol, start = 0, limit = 10) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `https://news.livemarketdata.com/newsgateway/search/news?f=symbol.ticker;symbol.sourceTicker&limit=${limit}&start=${start}&q=${symbol}_OMX_IS`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getAnnualInterimAccounts: async (symbol, period) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/questor/available_reports/symbol/${symbol}/periods/${period}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getFinancialSummaryData: async (symbol, period, key) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/questor/key_series/?ticker=${symbol}&key_id=${key}&periods=${period}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getIndicesData: async (keyId, startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/indexes/symbol/${keyId}/snapshot/?from_date=${startDate}8&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataPassengers: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/int_number_of_passengers/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataASK: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/int_available_seat_kilometers/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataLoadFactor: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/int_load_factor/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataRegionalNOP: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/reg_number_of_passengers/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataRegionalASK: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/reg_available_seat_kilometers/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataRegionalLF: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/reg_load_factor/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataCFU: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/charter_fleet_utilization/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataCSBH: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/charter_sold_block_hours/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getTrafficDataCargo: async (startDate, endDate) => {
    const token = readCookie("access_token");
    return axios
      .get(
        `${baseURL}market_data/v1/series/cargo_freight_tonne_kilometers/history?from_date=${startDate}&to_date=${endDate}`,
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .catch(errorHandler);
  },

  getBasicCurrencyData: async (symbol) => {
    const token = readCookie("access_token");
    return axios
      .get(`${baseURL}static_data/v1/basic_data_currency/symbol/${symbol}`, {
        headers: { Authorization: "Bearer " + token },
      })
      .catch(errorHandler);
  },
};
