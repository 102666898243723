export const availableFunds: { Tickertype: string, Symbol: string, Name: string }[] = [
    {
        Tickertype: "fund",
        Symbol: "jupiter_lausafj",
        Name: "Kvika - Lausafjársjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_eignasafn_i",
        Name: "ÍV Eignasafn I"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_eignasafn_ii",
        Name: "ÍV Eignasafn III"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_sklbrsafn",
        Name: "ÍV Skuldabréfasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_cb_sjodur",
        Name: "IS Sértryggð skuldabréf "
    },
    {
        Tickertype: "fund",
        Symbol: "isl_cbi_sjodur",
        Name: "IS Sértryggð skuldabréf VTR"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_lausa",
        Name: "IS Lausafjársafn"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_skbrval",
        Name: "Stefnir - Skuldabréfaval"
    },
    {
        Tickertype: "fund",
        Symbol: "frjalsi_1",
        Name: "Frjálsi 1"
    },
    {
        Tickertype: "fund",
        Symbol: "frjalsi_2",
        Name: "Frjálsi 2"
    },
    {
        Tickertype: "fund",
        Symbol: "frjalsi_3",
        Name: "Frjálsi 3"
    },
    {
        Tickertype: "fund",
        Symbol: "frjalsi_a",
        Name: "Frjálsi Áhætta"
    },
    {
        Tickertype: "fund",
        Symbol: "lif_I",
        Name: "Líf I"
    },
    {
        Tickertype: "fund",
        Symbol: "lif_II",
        Name: "Líf II"
    },
    {
        Tickertype: "fund",
        Symbol: "lif_III",
        Name: "Líf III"
    },
    {
        Tickertype: "fund",
        Symbol: "lif_IV",
        Name: "Líf IV"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_a_b",
        Name: "IS Einkasafn A (fl. B)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_b_b",
        Name: "IS Einkasafn B (fl. B)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_c_b",
        Name: "IS Einkasafn C (fl. B)"
    },
    {
        Tickertype: "fund",
        Symbol: "VELTII",
        Name: "Landsbréf – Veltubréf hs."
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_eignalI",
        Name: "Kvika Eignaleið I - Skuldabréfasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_eignalII",
        Name: "Kvika Eignaleið II - Varfærið safn"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_eignalIII",
        Name: "Kvika Eignaleið III - Blandað safn"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_d_b",
        Name: "IS Einkasafn D (fl. B)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_e_b",
        Name: "IS Einkasafn E (fl. B)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einkaer_alis",
        Name: "IS Einkasafn Erlent (ISK fl.A)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einkaer_ebis",
        Name: "IS Einkasafn Erlent (ISK fl.B)"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_eignalV",
        Name: "Kvika Eignaleið V - Hlutabréfasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_spari",
        Name: "Stefnir - Sparifjársjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_innlend_skbr",
        Name: "Kvika - Innlend skuldabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_hlutabrefavisitala",
        Name: "Kvika - Hlutabréfavísitala"
    },
    {
        Tickertype: "fund",
        Symbol: "gamma_covered",
        Name: "Kvika - Sértryggð skuldabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_eignasislsj",
        Name: "IS Eignasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_samval",
        Name: "Stefnir Samval"
    },
    {
        Tickertype: "fund",
        Symbol: "EIGNAB",
        Name: "Landsbréf – Eignadreifing langtíma hs."
    },
    {
        Tickertype: "fund",
        Symbol: "isl_graen",
        Name: "IS Græn skuldabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_sjodur5",
        Name: "IS Ríkisskuldabréf meðallöng"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_skbrsafn",
        Name: "IS Skuldabréfasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_rksskbr",
        Name: "Kvika - Ríkisverðbréf"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_rksvbovt",
        Name: "Stefnir Ríkisbréfasjóður óverðtryggður"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_rksvbml",
        Name: "Stefnir Ríkisverðbréfasjóður-millilangur"
    },
    {
        Tickertype: "fund",
        Symbol: "MAB",
        Name: "Landsbréf – Markaðsbréf hs."
    },
    {
        Tickertype: "fund",
        Symbol: "SPA",
        Name: "Landsbréf – Sparibréf meðallöng hs."
    },
    {
        Tickertype: "fund",
        Symbol: "SPAOVT",
        Name: "Landsbréf – Sparibréf óverðtryggð hs."
    },
    {
        Tickertype: "fund",
        Symbol: "iv_askrftrksbr",
        Name: "ÍV Áskriftarsjóður ríkisverðbréfa"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_kfglobal",
        Name: "Katla Fund - Global Equity"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_scandfund",
        Name: "Stefnir Scandinavian Fund"
    },
    {
        Tickertype: "fund",
        Symbol: "LN40",
        Name: "Landsbréf - Nordic 40 hs."
    },
    {
        Tickertype: "fund",
        Symbol: "isl_sjodur10",
        Name: "IS Hlutabréfasjóðurinn"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_sjodur6",
        Name: "IS Úrvalsvísitölusjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_innlhltbr",
        Name: "Kvika - Innlend hlutabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_is15",
        Name: "Stefnir - Innlend hlutabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "LEQ",
        Name: "Landsbréf - LEQ UCITS ETF hs."
    },
    {
        Tickertype: "fund",
        Symbol: "URB",
        Name: "Landsbréf – Úrvalsbréf hs."
    },
    {
        Tickertype: "fund",
        Symbol: "ONB",
        Name: "Landsbréf – Öndvegisbréf hs."
    },
    {
        Tickertype: "fund",
        Symbol: "iv_hltbrs",
        Name: "ÍV Íslensk hlutabréf - Aðallisti hs."
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_a_a",
        Name: "IS Einkasafn A (fl. A)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_b_a",
        Name: "IS Einkasafn B (fl. A)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_c_a",
        Name: "IS Einkasafn C (fl. A)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_d_a",
        Name: "IS Einkasafn D (fl. A)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_einka_e_a",
        Name: "IS Einkasafn E (fl. A)"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_sjodur7",
        Name: "IS Ríkisskuldabréf löng"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_rksvbvt",
        Name: "Stefnir Ríkisskuldabréf verðtryggð"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_rksvbl",
        Name: "Stefnir Ríkisverðbréfasjóður - langur"
    },
    {
        Tickertype: "fund",
        Symbol: "SPAVT",
        Name: "Landsbréf – Sparibréf verðtryggð hs."
    },
    {
        Tickertype: "fund",
        Symbol: "iv_rsk",
        Name: "ÍV Ríkisskuldabréfasjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_veltusafn",
        Name: "IS Veltusafn"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_lausafj",
        Name: "Stefnir Lausafjársjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_skammtsj",
        Name: "ÍV Skammtímasjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_rkssafn",
        Name: "IS Ríkissafn - ríkisskuldabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_rksvbs",
        Name: "Kvika - Ríkisverðbréf stutt"
    },
    {
        Tickertype: "fund",
        Symbol: "SPASTUTT",
        Name: "Landsbréf – Sparibréf stutt hs."
    },
    {
        Tickertype: "fund",
        Symbol: "iv_spari",
        Name: "ÍV Sparisafn"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_equus",
        Name: "IS EQUUS Hlutabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "akta_riki",
        Name: "Akta Ríki - ríkisskuldabréfasjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "akta_alvidra",
        Name: "Akta Alviðra"
    },
    {
        Tickertype: "fund",
        Symbol: "akta_vaxtat",
        Name: "Akta VaxtaTækifæri"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_stokkur",
        Name: "ÍV Stokkur"
    },
    {
        Tickertype: "fund",
        Symbol: "akta_stokkur",
        Name: "Akta Stokkur"
    },
    {
        Tickertype: "fund",
        Symbol: "EINKABREFA",
        Name: "Landsbréf – Einkabréf A hs."
    },
    {
        Tickertype: "fund",
        Symbol: "EIGNASJALF",
        Name: "Landsbréf – Eignadreifing sjálfbær hs."
    },
    {
        Tickertype: "fund",
        Symbol: "iv_eignasafn_2",
        Name: "ÍV Eignasafn II"
    },
    {
        Tickertype: "fund",
        Symbol: "EIGNAVAR",
        Name: "Landsbréf – Eignadreifing varfærin hs."
    },
    {
        Tickertype: "fund",
        Symbol: "akta_vaxtaverold",
        Name: "Akta VaxtaVeröld"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_innlanaleid",
        Name: "Innlánaleið"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_rikisbr_long",
        Name: "Löng ríkissk.bréf"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_styring_a",
        Name: "Stýring A"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_styring_b",
        Name: "Stýring B   "
    },
    {
        Tickertype: "fund",
        Symbol: "isl_styring_c",
        Name: "Stýring C"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_styring_d",
        Name: "Stýring D"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_fhl",
        Name: "Húsnæðisleið"
    },
    {
        Tickertype: "fund",
        Symbol: "MSER",
        Name: "Landsbréf – Markaðsbréf sértryggð hs."
    },
    {
        Tickertype: "fund",
        Symbol: "EINKABREFB",
        Name: "Landsbréf - Einkabréf B hs."
    },
    {
        Tickertype: "fund",
        Symbol: "EINKABREFC",
        Name: "Landsbréf - Einkabréf C hs."
    },
    {
        Tickertype: "fund",
        Symbol: "EINKAD",
        Name: "Landsbréf - Einkabréf D hs."
    },
    {
        Tickertype: "fund",
        Symbol: "SBL",
        Name: "Landsbréf – Sparibréf plús hs."
    },
    {
        Tickertype: "fund",
        Symbol: "kvika_eldgja",
        Name: "Kvika - Eldgjá"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_styring_e",
        Name: "Stýring E"
    },
    {
        Tickertype: "fund",
        Symbol: "kvika_stuttsk",
        Name: "Kvika - Stutt skuldabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_eignalIV",
        Name: "Kvika Eignaleið IV - Vaxtarsafn"
    },
    {
        Tickertype: "fund",
        Symbol: "FYRSKU",
        Name: "Landsbréf – Fyrirtækjaskuldabréf hs."
    },
    {
        Tickertype: "fund",
        Symbol: "isl_ovtsjis",
        Name: "IS Ríkisskuldabréf óverðtryggð"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_eignasislsjrs",
        Name: "IS Eignasafn - Ríki og sjóðir"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_kfglobal_isk",
        Name: "Katla Fund - Global Equity (ISK)"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_scandfund_isk",
        Name: "Stefnir Scandinavian Fund (ISK)"
    },
    {
        Tickertype: "fund",
        Symbol: "LGP",
        Name: "Landsbréf – Global Portfolio hs."
    },
    {
        Tickertype: "fund",
        Symbol: "isl_sjodur12",
        Name: "IS Heimssafn"
    },
    {
        Tickertype: "fund",
        Symbol: "LGE",
        Name: "Landsbréf - Global Equity Fund hs."
    },
    {
        Tickertype: "fund",
        Symbol: "iv_althltbr",
        Name: "ÍV Alþjóðlegur hlutabréfasjóður ISK"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_erlhltrs",
        Name: "ÍV Erlent hlutabréfasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_eignasafnIII",
        Name: "ÍV Eignasafn IV"
    },
    {
        Tickertype: "fund",
        Symbol: "EIGNAVOX",
        Name: "Landsbréf – Eignadreifing vöxtur hs."
    },
    {
        Tickertype: "fund",
        Symbol: "EIGNAVIRD",
        Name: "Landsbréf – Eignadreifing virði hs."
    },
    {
        Tickertype: "fund",
        Symbol: "VELTPLUS",
        Name: "Landsbréf – Veltubréf plús hs."
    },
    {
        Tickertype: "fund",
        Symbol: "GLOBALMULTI",
        Name: "Landsbréf - Global Multi Asset Fund hs."
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_ardgrsj",
        Name: "Stefnir - Arðgreiðslusjóður hs. (gengisruna)"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_graena",
        Name: "Stefnir - Grænaval"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_sjalfba",
        Name: "Stefnir - Sjálfbær skuldabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "gamma_index",
        Name: "Kvika - Ríkisskuldabréfavísitala"
    },
    {
        Tickertype: "fund",
        Symbol: "EINKABREFE",
        Name: "Landsbréf – Einkabréf E hs."
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_vaxtasj",
        Name: "Stefnir - Vaxtasjoður"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_vaxtast",
        Name: "IS Vaxtastefna"
    },
    {
        Tickertype: "fund",
        Symbol: "akta_lausi",
        Name: "Akta Lausafjársjóður hs."
    },
    {
        Tickertype: "fund",
        Symbol: "siv_hlutabref",
        Name: "SIV Hlutabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_sjodur6",
        Name: "IS Úrvalsvísitölusjóður"
    },
    {
        Tickertype: "fund",
        Symbol: "jupiter_innlhltbr",
        Name: "Kvika - Innlend hlutabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "af_vaxtabref",
        Name: "A/F Vaxtabréf hs."
    },
    {
        Tickertype: "fund",
        Symbol: "isl_aevileid",
        Name: "Ævileið"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_f_reikn_ov",
        Name: "Óverðtryggður lífeyrisreikningur"
    },
    {
        Tickertype: "fund",
        Symbol: "isl_f_reikn_vt",
        Name: "Verðtryggður lífeyrisreikningur"
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_innlhlutVogun",
        Name: "Stefnir - Innlend hlutabr?f Vogun hs."
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_sustainartic",
        Name: "Stefnir - Sustainable Arctic Fund hs."
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_kfglobal",
        Name: "Katla Fund - Global Equity"
    },
    {
        Tickertype: "fund",
        Symbol: "HEKLA",
        Name: "Landsbréf – Hekla hs. "
    },
    {
        Tickertype: "fund",
        Symbol: "iv_erlhltrs",
        Name: "ÍV Erlent hlutabréfasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "iv_erlsklbrsafn",
        Name: "ÍV Erlent skuldabréfasafn"
    },
    {
        Tickertype: "fund",
        Symbol: "akta_vaxtat",
        Name: "Akta VaxtaTækifæri"
    },
    {
        Tickertype: "fund",
        Symbol: "siv_hlutabref",
        Name: "SIV Hlutabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "siv_skuldabref",
        Name: "SIV Skuldabréf"
    },
    {
        Tickertype: "fund",
        Symbol: "kvika_heimur",
        Name: "Kvika - Heimur"
    },
    {
        Tickertype: "fund",
        Symbol: "siv_skammtsj",
        Name: "SIV Skammtímasjóður hs."
    },
    {
        Tickertype: "fund",
        Symbol: "stefnir_ardgrsj_adj",
        Name: "Stefnir - Arðgreiðslusjóður hs. (arðsleiðr.vísit.)"
    },
    {
        Tickertype: "fund",
        Symbol: "siv_lausafj",
        Name: "SIV Lausafjársjóður hs."
    },
    {
        Tickertype: "fund",
        Symbol: "HEKLA",
        Name: "Landsbréf – Hekla hs. "
    },
    {
        Tickertype: "fund",
        Symbol: "af_vaxtabref",
        Name: "A/F Vaxtabréf hs."
    }
].sort((a, b) => a.Name.localeCompare(b.Name));