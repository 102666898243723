
//PACKAGE IMPORTS:
import React, { useRef } from 'react';
import { Navigate, redirect } from 'react-router-dom';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useIsAuthenticated, useSignIn } from 'react-auth-kit'
//COMPONENT IMPORTS:
import Header from '../Components/Ui-elements/Header';
import Input from '../Components/Ui-elements/Input/Input';
import Button from '../Components/Ui-elements/Button/Button';
import Footer from '../Components/Ui-elements/Footer';
import Alert from '../Components/Ui-elements/Alert/Alert';
//ASSETS IMPORTS:
import normalLogo from '../Assets/Images/normalLogo.svg'
//UTILS IMPORTS:
import { GET_NEW_TOKEN_URL, setCookie } from '../Utils/api';
//Types IMPORTS:
import { IRequestLoginBody } from '../Types/GeneralTypes';
import Checkbox from '../Components/Ui-elements/Checkbox/Checkbox';

const TOAST_DELAY = 5000;

const Login: React.FC = () => {
    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [contactResponse, setContactResponse] = React.useState("");
    const [showToast, setShowToast] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [checkBox, setCheckBox] = React.useState(false);

    const form = useRef(null);
    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()

    const handleFormSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true)
        try {
            const requestBody: IRequestLoginBody = {
                username,
                password,
                system_id: 'ir_pages'
            };
            const url = GET_NEW_TOKEN_URL();
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });
            if (response.ok) {
                setLoading(false);
                const body: { access_token: string, expires_in: number } = await response.json();
                //SUCCESSFUL SIGNIN
                if (signIn(
                    {
                        token: body.access_token,
                        expiresIn: body.expires_in,
                        tokenType: "Bearer",
                        authState: { name: username, email: username },
                    }
                )) {
                    //set the cookie
                    setCookie('access_token', body.access_token);
                    // Redirect
                    return redirect("/all-ir-widgets");
                } else {
                    //Throw error
                }
            } else if (response.status === 401) {
                setLoading(false)
                setContactResponse("Incorrect credentials")
                setShowToast(true)
            } else {
                setLoading(false);
                setContactResponse("Request failed")
                setShowToast(true)
            }
        } catch (e) {
            setLoading(false);
            setContactResponse("Network Error")
            setShowToast(true);
            console.log(e)
        }
    }
    if (isAuthenticated()) return <Navigate to={'/all-ir-widgets'} />

    return <>
        <div className="IR_login">
            <Header scroll={true} />
            <div className='page_content'>
                <div className='loginModal'>
                    <form ref={form} className="login-form" onSubmit={handleFormSubmit}>
                        <div className="imageWrapper">
                            <img className='logo' src={normalLogo} alt="logo" />
                        </div>
                        <Input
                            className='inputField'
                            required={true}
                            type={"text"}
                            name={"username"}
                            id={"username"}
                            label={"Username"}
                            value={username}
                            onChange={(a: string) => setUsername(a)} />
                        <Input
                            className='inputField'
                            required={true}
                            type={'password'}
                            name={'password'}
                            id={'password'}
                            label={'Password'}
                            value={password}
                            onChange={(e: string) => setPassword(e)} />
                        <div className='checkboxWrapper' onClick={() => setCheckBox(!checkBox)}>
                            <Checkbox
                                className="rememberMe"
                                checked={checkBox}
                                label={'Remember me'} />
                        </div>
                        <div className="jc-center">
                            <Button showLoading={loading} buttonType='submit' size='lg'>Login</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div className='footerWrapper'>
            <Footer onlyDisplaySecondFooter />
        </div>
        <ToastContainer className='toastContainer' >
            <Toast onClose={() => setShowToast(false)} autohide delay={TOAST_DELAY} show={showToast}>
                <Alert type={'error'} headText={contactResponse} />
            </Toast>
        </ToastContainer>
    </>
}

export default Login;
